<template>
    <messages-page title="Общая информация" :messages-list="MESSAGES_INFO" @read="read" @download="download" />
</template>

<script>
    import MessagesPage from "@/components/pages/cabinet/messages/MessagesPage";
    import {mapActions, mapGetters} from "vuex";
    export default {
        name: "MessagesInfo",
      components: {MessagesPage},
        computed: {
            ...mapGetters("Messages", ["MESSAGES_INFO"])
        },
        methods: {
            ...mapActions("Sockets", ["SOCKET_READ"]),
            ...mapActions("regModule", ["DOWNLOAD_FILE"]),
            read(id) {
                this.SOCKET_READ(id)
            },
            download(file) {
                this.DOWNLOAD_FILE(file)
            }
        }
    }
</script>
